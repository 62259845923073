import { useEffect, useState } from 'react'
import WaitlistDisconnected from './WaitlistDisconnected'
import WaitlistFormMail from './WaitlistFormMail'
import './waitlist.css'
import { useWeb3ModalAccount, useDisconnect } from '@web3modal/ethers5/react'
import { useModalContext } from '../components/ModalProvider'
import Promos from '../components/Promos'
import Logos from '../components/Logos'
import { FEATURE_FLAG_ATLAS_MINER_PURCHASE } from '../config'

interface WaitlistEntry {
  id: number
  order: number
  walletAddress: string
  emailValidatedAt: Date
  addressVerifiedAt: Date
  email: string
  minerOrder: null | any
}

const Waitlist = () => {
  const [userWallet, setUserWallet] = useState('')
  const [jwtToken, setJwtToken] = useState('')

  const { openModal, modalProps } = useModalContext()

  const [waitlistEntry, setWaitlistEntry] = useState<WaitlistEntry | null>(null)
  const { isConnected, address } = useWeb3ModalAccount()
  const { disconnect } = useDisconnect()
  const [disabledBuyButton, setDisableBuyButton] = useState(false)

  const onDisconnect = () => {
    setUserWallet('')
    setJwtToken('')
    setWaitlistEntry(null)
    disconnect()
    localStorage.removeItem('token-miner')
  }

  useEffect(() => {
    modalProps({ email: waitlistEntry?.email, setDisableBuyButton })
  }, [waitlistEntry, jwtToken, userWallet])

  return (
    <>
      <div className="text-center z-3">
        <div className="flex w-full justify-end px-[30px]">
          {isConnected && (
            <button
              onClick={() => {
                onDisconnect()
              }}
              className="inline-flex w-auto cursor-pointer select-none appearance-none items-center justify-center rounded border border-blue-700 bg-blue-700 px-3 py-2 text-sm font-medium text-white transition hover:border-blue-800 hover:bg-blue-800 focus:border-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-300 disabled:pointer-events-none disabled:opacity-75"
            >
              Disconnect wallet
            </button>
          )}
        </div>

        <div className="flex items-center px-[30px] max-md:flex-col-reverse">
          <div className="w-1/2 flex flex-col max-w-4xl max-md:w-full">
            <h1 className="text-lg md:text-xl lg:text-2xl font-regular text-white text-left mt-4 block w-full max-md:text-center">
              Join the waitlist to check
              <span className="text-4xl md:text-5xl lg:text-6xl font-bold tracking-wider text-white mt-4 block text-left gradient-text max-md:text-center">
                AtlasMiner
              </span>
            </h1>
            <div className="mt-10 text-white w-full max-w-4xl flex justify-center text-left flex-col">
              <h2 className="text-lg mb-5 max-md:text-center">
                <strong>The Atlas Miner is finally here:</strong>
              </h2>
              <ul className="list-disc self-center text-left max-w-xl mb-5 flex flex-col gap-y-2">
                <li>
                  <a
                    href="https://cointelegraph.com/explained/decentralized-physical-infrastructure-network-depin-explained"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="font-bold hover:text-blue-800 transition-colors duration-500"
                  >
                    #DePIN
                  </a>{' '}
                  hardware device
                </li>
                <li>Connects to any dash camera</li>
                <li>Analyzes the video stream in real time, locally</li>
                <li>
                  Uses A.I. computer vision algorithms to detect traffic issues
                  (accidents, congestion, road closures and road work and police
                  cars)
                </li>
                <li>
                  Sends detections to other drivers on Atlas Navi to warn them
                  or have their routes changed to avoid those areas
                </li>
                <li>
                  Collects driving footage, anonymizes it and helps vehicle
                  manufacturers to train their self driving vehicles with data
                </li>
              </ul>
              <p className="mb-5 max-md:text-center">
                <strong>
                  Get an Atlas Miner and get rewarded for every mile driven with
                  it
                </strong>
              </p>
              <p className=" max-md:text-center">
                Join the whitelist and get paid in $NAVI tokens for every mile
                driven, in addition to any revenues earned by using Atlas Navi
                NFTs
              </p>
              <div className="text-left justify-left mt-8">
                {userWallet && jwtToken ? (
                  waitlistEntry?.emailValidatedAt === null ? (
                    <WaitlistFormMail
                      jwtToken={jwtToken}
                      setWaitlistEntry={setWaitlistEntry}
                    />
                  ) : (
                    <>
                      <h1 className="text-2xl font-bold text-white">
                        Thank you for joining the waitlist!
                      </h1>
                      <h3 className="text-xl font-bold mt-3">
                        You're position is {waitlistEntry?.order}
                      </h3>
                      {typeof waitlistEntry?.order !== 'undefined' &&
                      waitlistEntry?.order <= 200 &&
                      !waitlistEntry?.minerOrder?.transactionHash &&
                      FEATURE_FLAG_ATLAS_MINER_PURCHASE === 'true' ? (
                        <button
                          disabled={disabledBuyButton}
                          className="inline-flex w-auto cursor-pointer select-none appearance-none items-center justify-center rounded border border-blue-700 bg-blue-700 px-3 py-2 text-sm font-medium text-white transition hover:border-blue-800 hover:bg-blue-800 focus:border-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-300 disabled:pointer-events-none disabled:opacity-75 mt-5"
                          onClick={openModal}
                        >
                          Order Product
                        </button>
                      ) : waitlistEntry?.minerOrder?.transactionHash ? (
                        <div className="border-white-500 rounded-md">
                          <h1 className="text-xl mt-5 font-bold text-white">
                            Your order for the Atlas Miner device has been
                            placed!
                          </h1>
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  )
                ) : (
                  <WaitlistDisconnected
                    setUserWallet={setUserWallet}
                    setJwtToken={setJwtToken}
                    setWaitlistEntry={setWaitlistEntry}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="w-1/2 img-container max-md:w-full">
            <div className="pulsate"></div>
            <img src="/assets/waitlist.png" alt="" className="main-image" />
          </div>
        </div>
      </div>

      <Logos />
      {FEATURE_FLAG_ATLAS_MINER_PURCHASE === 'true' && <Promos />}
    </>
  )
}

export default Waitlist
