import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
  useDisconnect,
} from '@web3modal/ethers5/react'

export function useWalletState() {
  const { open } = useWeb3Modal()
  const { isConnected, address } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()
  const { disconnect } = useDisconnect()

  return { open, isConnected, address, walletProvider, disconnect }
}
