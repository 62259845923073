const NotFound404 = () => {
  return (
    <>
      <div className="flex flex-col items-center justify-center px-10">
        <p className="text-6xl md:text-7xl lg:text-9xl font-bold tracking-wider text-white">
          404
        </p>
        <p className="text-2xl md:text-3xl lg:text-5xl font-bold tracking-wider text-white mt-4">
          Page Not Found
        </p>
        <p className="text-white mt-4 pb-4 border-b-2 text-center border-white/10">
          Sorry, the page you are looking for could not be found.
        </p>
        <a href="/" className="btn btn-accent mt-5" title="Return Home">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span>Return Home</span>
        </a>
      </div>
    </>
  )
}
export default NotFound404
