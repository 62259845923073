import { useContext, useState, createContext } from 'react'

type ModalContextType = {
  isModalOpen: boolean
  modalPayload: any
  modalProps: (payload: any) => void
  openModal: () => void
  closeModal: () => void
}

export const ModalContext = createContext<ModalContextType | undefined>(
  undefined,
)

export const useModalContext = () => {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error('useModalContext must be used within a ModalProvider')
  }
  return context
}

export const ModalProvider = ({ children }: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalPayload, setModalPayload] = useState({})

  const openModal = () => setIsModalOpen(true)
  const closeModal = () => setIsModalOpen(false)
  const modalProps = (payload: any) => setModalPayload(payload)

  return (
    <ModalContext.Provider
      value={{ modalProps, modalPayload, isModalOpen, openModal, closeModal }}
    >
      {children}
    </ModalContext.Provider>
  )
}
