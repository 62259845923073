import { useEffect, useState } from 'react'
import AuthCode from 'react-auth-code-input'
import ReCAPTCHA from 'react-google-recaptcha'
import { API_URL, RECAPTCHA_SITE_KEY } from '../config'
import WaitingListConfirmation from './WaitlistConfirmation'

const WaitlistFormCode = (props: any) => {
  const { jwtToken, setWaitlistEntry } = props
  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [captchaToken, setCaptchaToken] = useState('')

  const validateValues = (mail: string) => {
    let error = ''

    if (captchaToken === '') {
      error = 'Please complete captcha'
    }

    if (code.length !== 6) {
      error = 'Code is required'
    }

    setError(error)
  }

  const submitForm = async () => {
    const verifyCodeRes = await fetch(API_URL + '/miner/email/verify-code', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        code: code,
        token: captchaToken,
      }),
    })
    if (!verifyCodeRes.ok) {
      if (verifyCodeRes.status === 401) {
        setError('Access denied for the requested resource. Please sign again')
      } else {
        setError('Something went wrong. Please try again later')
      }
      setFormSubmitted(false)
      return
    }

    const parseSendCodeRes = await verifyCodeRes.json()
    if (parseSendCodeRes.error) {
      setError(parseSendCodeRes.message)
      setFormSubmitted(false)
    }
    // submit form
    setFormSubmitted(true)
    setWaitlistEntry(parseSendCodeRes.waitlistEntry)
  }

  useEffect(() => {
    // submit if there are no errors
    if (error === '' && submitting && captchaToken !== '') {
      submitForm()
    }
  }, [captchaToken, error, submitting])

  const handleChange = (res: string) => {
    if (res.length === 6) setError('')
    setCode(res)
  }

  const onChangeRecaptcha = (value: any) => {
    setCaptchaToken(value)
  }

  return (
    <>
      {formSubmitted ? (
        <WaitingListConfirmation />
      ) : (
        <>
          <p className="justify-center items-center text-white max-md:text-center">
            Enter the verification code you received on the provided mail.
          </p>
          <form
            className="px-2 flex flex-col gap-3"
            onSubmit={(e) => {
              e.preventDefault()
              validateValues(code)
              setSubmitting(true)
            }}
          >
            <label className="form-control w-full">
              <div className="label">
                {error && (
                  <span className="label-text-alt text-error">{error}</span>
                )}
              </div>
              <div className="relative w-full max-w-[600px] flex max-md:mx-auto space-x-2 max-md:justify-center xs:space-x-3 flex-wrap">
                <AuthCode
                  onChange={handleChange}
                  inputClassName={`input input-bordered text-black bg-white placeholder-black w-[40px] xs:w-[48px] h-[40px] xs:h-[48px] text-center p-0 ${
                    error ? 'input-error' : 'border-black/40'
                  }`}
                  containerClassName="flex space-x-2 xs:space-x-3 mt-2"
                  allowedCharacters="numeric"
                />

                <button
                  className="btn p-0 w-[40px] xs:w-[48px] min-h-[40px] h-[40px] xs:min-h-[48px] xs:h-[40px] mt-2 border-blue-700 bg-blue-700 hover:bg-blue-800 hover:border-blue-800 focus:ring-blue-300"
                  type="submit"
                >
                  <svg
                    className="h-6 w-6 fill-current xs:h-8 xs:w-8"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"></path>
                  </svg>
                </button>
              </div>
              <div className="w-[240px] xs:w-full xs:max-w-[304px] max-md:mx-auto recaptcha overflow-hidden mt-5">
                <ReCAPTCHA
                  sitekey={RECAPTCHA_SITE_KEY!}
                  onChange={onChangeRecaptcha}
                />
              </div>
            </label>
          </form>
        </>
      )}
    </>
  )
}

export default WaitlistFormCode
