import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import * as jwt from 'jsonwebtoken'
import 'react-toastify/dist/ReactToastify.css'
import { API_URL } from '../config'
import {
  useWeb3ModalProvider,
  useWeb3Modal,
  useWeb3ModalAccount,
  useDisconnect,
} from '@web3modal/ethers5/react'
import { providers } from 'ethers'

const WaitlistDisconnected = (props: any) => {
  const { setUserWallet, setJwtToken, setWaitlistEntry } = props
  const navigate = useNavigate()
  const [waitingForSignature, setWaitingForSignature] = useState(false)

  const { walletProvider } = useWeb3ModalProvider()
  const { address, isConnected } = useWeb3ModalAccount()
  const { disconnect } = useDisconnect()
  const { open } = useWeb3Modal()

  const fetchUserInfo = async () => {
    if (!isConnected) {
      return
    }
    // if connected, set address to state
    setUserWallet(address)
    //retrieve token from localstorage
    const token = localStorage.getItem('token-miner')
    if (!token) {
      walletConnect()
      return
    }
    // decode token to verify address and expiration
    const { walletAddress, exp } = jwt.decode(token) as jwt.JwtPayload

    console.log(exp)
    if (
      walletAddress.toLowerCase() !== address?.toLowerCase() ||
      exp! < parseInt((Date.now() / 1000).toFixed(0))
    ) {
      walletConnect()
      return
    }

    // if there is a valid token, we need to fetch the waitlistEntry linked to this walletAddress
    // based on response, change screen
    // if there is no entry or error, walletConnect will connect wallet and create a new entry after validation
    try {
      const waitlistInfo = await fetch(API_URL + '/miner/email/waitlist', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })

      if (!waitlistInfo.ok) {
        walletConnect()
        return
      }

      const unlockResBody = await waitlistInfo.json()
      const { waitlistEntry } = unlockResBody

      setWaitlistEntry(waitlistEntry)
      setJwtToken(token)
    } catch (error) {
      console.error('Error fetching user info:', error)
      walletConnect()
      return
    }
  }

  useEffect(() => {
    fetchUserInfo()
  }, [isConnected, address])

  const walletConnect = async () => {
    if (!walletProvider) {
      console.error('[WALLETCONNECT] Failed')
      return
    }
    setWaitingForSignature(true)
    const provider = new providers.Web3Provider(walletProvider)
    const signer = provider.getSigner()

    const loginRes = await fetch(API_URL + '/miner/auth/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        wallet: address,
      }),
    })

    if (loginRes.status === 404) {
      navigate('/nft/notfound')
      setWaitingForSignature(false)
      return
    }

    const loginResBody = await loginRes.json()
    const nonce = loginResBody.nonce

    try {
      const signature = await signer.signMessage(nonce)

      const unlockRes = await fetch(API_URL + '/miner/auth/unlock', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          wallet: address,
          signature,
        }),
      })
      const unlockResBody = await unlockRes.json()
      const { token, waitlistEntry } = unlockResBody
      localStorage.setItem('token-miner', token)
      setJwtToken(token)
      setWaitlistEntry(waitlistEntry)
      setWaitingForSignature(false)

      return {
        success: true,
        message: '',
      }
    } catch (error: any) {
      disconnect().then(() => {
        setJwtToken('')
        setWaitlistEntry(null)
        setWaitingForSignature(false)
        localStorage.removeItem('token-miner')
      })
      alert(error.message)
    }
  }

  return (
    <>
      <p className="justify-center items-center mb-4 max-md:text-center">
        Atlas miner is currently invite-only.
      </p>
      <div className="flex flex-col w-full items-start justify-start mt-5 max-md:justify-center">
        <button
          onClick={() => open()}
          className="inline-flex w-auto cursor-pointer mb-5 select-none appearance-none items-center justify-center space-x-2 rounded border border-blue-700 bg-blue-700 px-3 py-2 text-sm font-medium text-white transition hover:border-blue-800 hover:bg-blue-800 focus:border-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-300 disabled:pointer-events-none disabled:opacity-75 max-md:mx-auto"
        >
          {!isConnected && 'Connect your wallet to join the waitlist'}
          {isConnected && waitingForSignature && (
            <>
              <svg className="h-4 w-4 animate-spin" viewBox="3 3 18 18">
                <path
                  className="fill-blue-800"
                  d="M12 5C8.13401 5 5 8.13401 5 12c0 3.866 3.13401 7 7 7 3.866.0 7-3.134 7-7 0-3.86599-3.134-7-7-7zM3 12c0-4.97056 4.02944-9 9-9 4.9706.0 9 4.02944 9 9 0 4.9706-4.0294 9-9 9-4.97056.0-9-4.0294-9-9z"
                ></path>
                <path
                  className="fill-blue-100"
                  d="M16.9497 7.05015c-2.7336-2.73367-7.16578-2.73367-9.89945.0-.39052.39052-1.02369.39052-1.41421.0-.39053-.39053-.39053-1.02369.0-1.41422 3.51472-3.51472 9.21316-3.51472 12.72796.0C18.7545 6.02646 18.7545 6.65962 18.364 7.05015c-.390599999999999.39052-1.0237.39052-1.4143.0z"
                ></path>
              </svg>
              <span>Waiting for confirmation...</span>
            </>
          )}
        </button>
      </div>
    </>
  )
}

export default WaitlistDisconnected
