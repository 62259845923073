const Promos = () => {
  return (
    <div className="flex gap-10 flex-col apply-border relative p-10 mt-10 mb-10 w-full max-w-[calc(100%_-_60px)] mx-auto">
      <h3 className="w-full text-center text-5xl font-bold apply-gradient uppercase">
        Buy atlas miner
      </h3>
      <div className="flex gap-10 text-white text-l text-center max-md:flex-col">
        <div className="flex flex-col gap-5 max-w-[33%] max-md:max-w-none">
          <h4 className="text-3xl font-bold uppercase">DePIN hardware device
</h4>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt
            reprehenderit amet, ea ipsum repellendus voluptates eius odit
            officiis doloribus vitae temporibus quam iste iusto animi nihil
            voluptatibus? Iusto, sint excepturi?
          </p>
        </div>
        <div className="flex flex-col gap-5 max-w-[33%] max-md:max-w-none">
          <h4 className="text-3xl font-bold uppercase">Connect to any dash camera</h4>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
        </div>
        <div className="flex flex-col gap-5 max-w-[33%] max-md:max-w-none">
          <h4 className="text-3xl font-bold uppercase">Analyzes the video stream in real time using A.I. </h4>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam sunt
            ipsa repellendus ipsum incidunt dolor et! Similique ex iusto veniam
            tempore sunt cupiditate facere, rerum labore. Consequuntur rem quae
            cum.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Promos
