import { useState, useEffect, useCallback } from 'react'
import { ethers } from 'ethers'
import { useWalletState } from '../hooks/useWalletState'
import { API_URL, NFT_CONTRACT_PROXY_ADDRESS } from '../config'
import { useNavigate } from 'react-router-dom'
import { classNames } from '../utils'
import { toast } from 'react-toastify'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { useModalContext } from '../components/ModalProvider'

type Props = {
  deliveryAddress: {
    address: string
    apt: string
    city: string
    country: string
    postcode: string
  }
  phone: string
  email: string
  firstname: string
  lastname: string
}

const BuyMinerNFT = ({
  deliveryAddress,
  email,
  firstname,
  lastname,
  phone,
}: Props) => {
  // UI CONTROLLERS
  const [showApproveButton, setShowApproveButton] = useState(false)
  const [approveButtonLoading, setApproveButtonLoading] = useState(false)
  const [initMintButtonLoading, setInitMintButtonLoading] = useState(false)
  const [mintButtonLoading, setMintButtonLoading] = useState(false)
  const [userWalletAddress, setUserWalletAddress] = useState<string>('')
  const [jwtToken, setJwtToken] = useState<string>('')
  const [payload, setPayload] = useState<any>({})
  const [carType, setCarType] = useState<any>({})
  const [mintType, setMintType] = useState<'standardMint' | 'mintPassMint'>(
    'standardMint',
  )
  const [enabled, setEnabled] = useState(true)
  const [initBuyTitle, setInitBuyTitle] = useState('Buy with NAVI')
  const [carTypesLoading, setCarTypesLoading] = useState(false)
  const [disabledInput, setDisabledInput] = useState(false)

  /////////
  const { walletProvider, isConnected, address } = useWalletState()
  const navigate = useNavigate()
  /////////
  const nftCtrAbi = require('../Contracts/nftContractAbi.json')
  const erc20Abi = require('../Contracts/erc20TokenAbi.json')
  /////////
  const { closeModal, modalPayload } = useModalContext()

  useEffect(() => {
    const { address, apt, city, country, postcode } = deliveryAddress

    if (
      firstname &&
      lastname &&
      email &&
      isValidPhoneNumber(phone) &&
      address &&
      city &&
      country &&
      postcode &&
      apt
    ) {
      setDisabledInput(false)
      return
    }

    setDisabledInput(true)
  }, [firstname, lastname, deliveryAddress, email, phone])

  const confirmDeliveryAddress = useCallback(async () => {
    const checkAddress = await fetch(API_URL + '/miner/order/buy-miner', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token-miner')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        firstName: firstname,
        lastName: lastname,
        email,
        phone,
        address: `Address: ${deliveryAddress.address}, Apt: ${deliveryAddress.apt}, City: ${deliveryAddress.city}, Country: ${deliveryAddress.country}, Postcode: ${deliveryAddress.postcode}`,
      }),
    })

    const checkAddressBody = await checkAddress.json()

    if (checkAddressBody.error) {
      toast.error(checkAddressBody.message)

      return {
        error: true,
      }
    }

    if (checkAddressBody.error === false) {
      // toast.success(checkAddressBody.message) TODO: uncomment this line when updated be message
      toast.info(
        'Please confirm your wallet ownership to proceed and continue with buying it.',
      )

      return {
        error: false,
      }
    }

    toast.error(
      'There is an error with the server. Please try again later. If this error persists, please contact support.',
    )

    return {
      error: true,
    }
  }, [deliveryAddress, email, firstname, lastname, phone])

  const confirmWalletOwnership = useCallback(async () => {
    if (!walletProvider) {
      console.error('[WALLETCONNECT] Failed')
      return
    }

    const provider = new ethers.providers.Web3Provider(walletProvider)
    const signer = provider.getSigner()

    // return

    const loginRes = await fetch(API_URL + '/nft/auth/login', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        wallet: address,
      }),
    })

    if (loginRes.status === 404) {
      navigate('/nft/notfound')
      return
    }

    const loginResBody = await loginRes.json()
    const nonce = loginResBody.nonce

    try {
      const signature = await signer.signMessage(nonce)

      const unlockRes = await fetch(API_URL + '/nft/auth/unlock', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          wallet: address,
          signature,
        }),
      })
      const unlockResBody = await unlockRes.json()
      const { token } = unlockResBody
      setUserWalletAddress(address as string)
      setJwtToken(token)
      return {
        success: true,
        message: '',
      }
    } catch (error: any) {
      console.error(error)
      alert(error.message)
    }
  }, [isConnected, address])

  const mint = useCallback(async () => {
    if (!walletProvider || !userWalletAddress || !jwtToken) {
      return
    }

    const provider = new ethers.providers.Web3Provider(walletProvider)
    const signer = provider.getSigner()

    setMintButtonLoading(true)
    const mintContract = new ethers.Contract(
      NFT_CONTRACT_PROXY_ADDRESS,
      nftCtrAbi,
      signer,
    )
    await mintContract
      .mint(
        carType.id,
        carType.limit,
        carType.paymentToken,
        payload.carPrice,
        payload.expiration,
        payload.signature,
      )
      .then(async () => {
        await toast
          .promise(new Promise((resolve) => setTimeout(resolve, 5000)), {
            pending: 'Waiting for blockchain event propagation...',
            success: 'NFT minted successfully',
          })
          .then((res) => {
            toast.success('Order placed successfuly!')
            modalPayload.setDisableBuyButton(true)
            closeModal()
          })
        setPayload(null)
      })
      .catch((err: any) => {
        console.log('error on mint ', err)
      })
      .finally(() => setMintButtonLoading(false))
  }, [
    walletProvider,
    userWalletAddress,
    jwtToken,
    setMintButtonLoading,
    carType,
    payload,
    setPayload,
  ])

  const validateAllowance = useCallback(
    async (paymentToken: string) => {
      if (!walletProvider || !userWalletAddress) {
        return
      }
      const provider = new ethers.providers.Web3Provider(walletProvider)
      const naviTokenContract = new ethers.Contract(
        paymentToken,
        erc20Abi,
        provider,
      )

      const minAllowanceAmount = ethers.utils.parseEther(
        '1000000000000000000000000000000',
      )
      await naviTokenContract
        .allowance(userWalletAddress, NFT_CONTRACT_PROXY_ADDRESS)
        .then((allowanceResponse: string) => {
          let responseInEther = ethers.utils.parseEther(
            allowanceResponse.toString(),
          )

          if (responseInEther < minAllowanceAmount) {
            //show approve button
            setShowApproveButton(true)
          } else {
            //allow mint
            setShowApproveButton(false)
          }
        })
        .catch((ex: any) => {
          console.log('error on validate allowance = ', ex)
        })
    },
    [setShowApproveButton, userWalletAddress, walletProvider],
  )
  useEffect(() => {
    const abort = new AbortController()
    const signal = abort.signal

    setCarTypesLoading(true)
    fetch(API_URL + '/nft/miner-type', { signal })
      .then((res) => res.json())
      .then((carTypes) =>
        setCarType(
          carTypes.find(
            (carType: any) =>
              carType.id ===
              parseInt(process.env.REACT_APP_ATLAS_MINER_ID_CLASS!),
          ),
        ),
      )
      .finally(() => setCarTypesLoading(false))

    return () => abort.abort()
  }, [])
  const initMint = useCallback(async () => {
    if (!walletProvider || !userWalletAddress || !jwtToken) {
      return
    }

    setInitMintButtonLoading(true)
    await fetch(API_URL + `/nft/mint`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        jwtToken: jwtToken,
        carTypeId: carType.id, //must be between 100.000 and 999.999
        mintType: mintType,
      }),
    })
      .then(async (res) => {
        if (res.status !== 200) {
          setInitMintButtonLoading(false)
          return res.json().then((resBody: any) => alert(resBody.message))
        } else {
          const unlockResBody = await res.json()
          setPayload(unlockResBody.payload)

          await validateAllowance(carType.paymentToken)
        }
      })
      .finally(() => {
        setInitMintButtonLoading(false)
      })
  }, [
    walletProvider,
    userWalletAddress,
    jwtToken,
    setInitMintButtonLoading,
    carType,
    setPayload,
    validateAllowance,
    mintType,
  ])

  const approve = useCallback(async () => {
    if (!walletProvider || !userWalletAddress || !jwtToken) {
      return
    }

    const provider = new ethers.providers.Web3Provider(walletProvider)
    const signer = provider.getSigner()

    const naviTokenContract = new ethers.Contract(
      carType.paymentToken,
      erc20Abi,
      signer,
    )

    setApproveButtonLoading(true)
    await naviTokenContract
      .approve(NFT_CONTRACT_PROXY_ADDRESS, '1000000000000000000000000000000')
      .then(() => setShowApproveButton(false))
      .catch((err: any) => {
        console.log('error on approve = ', err)
      })
      .finally(() => setApproveButtonLoading(false))
  }, [
    carType,
    jwtToken,
    setApproveButtonLoading,
    setShowApproveButton,
    userWalletAddress,
    walletProvider,
  ])

  return (
    <>
      {!isConnected ? (
        <button
          className={`${
            initMintButtonLoading ? 'loading' : ''
          } btn btn-success px-4 py-2 disabled:text-white`}
          disabled
        >
          Wallet not connected
        </button>
      ) : isConnected && (!jwtToken.length || !userWalletAddress.length) ? (
        <button
          className={`${
            initMintButtonLoading ? 'loading' : ''
          } btn btn-success px-4 py-2 disabled:text-white`}
          disabled={initMintButtonLoading || disabledInput}
          onClick={async () => {
            // check if address is saved, if not give error
            const deliveryAdress = await confirmDeliveryAddress()
            if (deliveryAdress.error) return
            confirmWalletOwnership()
          }}
        >
          {initMintButtonLoading ? '' : 'Confirm wallet Ownership'}
        </button>
      ) : (
        <div>
          {!payload?.signature ? (
            <button
              className={`${
                initMintButtonLoading ? 'loading' : ''
              } btn btn-success px-4 py-2 disabled:text-white`}
              disabled={
                !jwtToken ||
                !walletProvider ||
                !userWalletAddress ||
                initMintButtonLoading
              }
              onClick={() => initMint()}
            >
              {initMintButtonLoading ? '' : initBuyTitle}
            </button>
          ) : null}
          {showApproveButton && (
            <button
              disabled={!payload?.signature || approveButtonLoading || !enabled}
              className={`${
                approveButtonLoading ? 'loading' : ''
              } btn btn-success px-4 py-2 disabled:text-white`}
              onClick={() => approve()}
            >
              {approveButtonLoading ? '' : 'Approve Spending'}
            </button>
          )}
          {payload?.signature && !showApproveButton && (
            <button
              className={classNames(
                mintButtonLoading ? 'loading' : '',
                'btn btn-success px-4 py-2 disabled:text-white',
              )}
              disabled={!payload?.signature || mintButtonLoading || !enabled}
              onClick={() => mint()}
            >
              {mintButtonLoading
                ? ''
                : `Buy for ${ethers.utils.formatEther(payload.carPrice)} NAVI`}
            </button>
          )}
        </div>
      )}
    </>
  )
}

export default BuyMinerNFT
