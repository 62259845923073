export const CHAIN_ID = parseInt(process.env.REACT_APP_CHAIN_ID!)
export const CHAIN_RPC = process.env.REACT_APP_CHAIN_RPC!
export const CHAIN_NAME = process.env.REACT_APP_CHAIN_NAME!
export const CHAIN_CURRENCY = process.env.REACT_APP_CHAIN_CURRENCY!
export const CHAIN_EXPLORER_URL = process.env.REACT_APP_CHAIN_EXPLORER_URL!
export const WEB_APP_URL = process.env.REACT_APP_WEB_APP_URL!
export const API_URL = process.env.REACT_APP_API_URL!
export const VESTING_CONTRACT_PROXY_ADDRESS =
  process.env.REACT_APP_VESTING_CONTRACT_PROXY_ADDRESS!
export const STAKING_CONTRACT_PROXY_ADDRESS =
  process.env.REACT_APP_STAKING_CONTRACT_PROXY_ADDRESS!
export const STAKING_CONTRACT_V2_PROXY_ADDRESS =
  process.env.REACT_APP_STAKING_V2_CONTRACT_PROXY_ADDRESS!
export const NFT_CONTRACT_PROXY_ADDRESS =
  process.env.REACT_APP_NFT_CONTRACT_PROXY_ADDRESS!
export const NAVI_TOKEN_ADDRESS = process.env.REACT_APP_NAVI_TOKEN_ADDRESS!

export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY!

export const WEB3MODAL_PROJECT_ID = process.env.REACT_APP_WEB3MODAL_PROJECT_ID!

export const WEB3MODAL_METADATA = {
  name: 'Atlas Navi',
  description: '',
  url: WEB_APP_URL,
  icons: [
    'https://static.tildacdn.com/tild6536-3438-4434-b634-656130653330/ATLAS_NAVI_LOGO.svg',
  ],
}

export const WEB3MODAL_MAINNET = {
  chainId: CHAIN_ID,
  name: CHAIN_NAME,
  currency: CHAIN_CURRENCY,
  explorerUrl: CHAIN_EXPLORER_URL,
  rpcUrl: CHAIN_RPC,
}

export const FEATURE_FLAG_ATLAS_MINER_PURCHASE =
  process.env.REACT_APP_FEATURE_FLAG_ATLAS_MINER_PURCHASE!
