import { useEffect, useState } from 'react'
import { useModalContext } from '../components/ModalProvider'

import 'react-phone-input-2/lib/style.css'
import './phone.css'
import PhoneInput from 'react-phone-input-2'
import BuyMinerNFT from './BuyMinerNFT'

const Modal = () => {
  const { isModalOpen, closeModal, modalPayload } = useModalContext()

  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [deliveryAdress, setDeliveryAddress] = useState({
    address: '',
    apt: '',
    city: '',
    country: '',
    postcode: '',
  })

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }

    return () => {
      document.body.style.overflow = ''
    }
  }, [isModalOpen])

  if (!isModalOpen) return null

  const handleChange = (e: any) => {
    if (e.target.name === 'firstname') {
      setFirstname(e.target.value)
    }

    if (e.target.name === 'lastname') {
      setLastname(e.target.value)
    }

    if (e.target.name === 'phone') {
      setPhoneNumber(e.target.value)
    }

    if (e.target.name.includes('deliveryAddress')) {
      const key = e.target.name.split('_')[1]

      setDeliveryAddress({
        ...deliveryAdress,
        [key]: e.target.value,
      })
    }
  }

  return (
    <div
      className="w-full h-full fixed top-0 left-0 bg-black/70 z-50 flex"
      onClick={closeModal}
    >
      <div className="w-full h-full absolute top-0 left-0 flex overflow-y-auto">
        <div
          className="modal-form m-auto w-full max-w-3xl p-10 bg-white flex flex-col gap-5"
          onClick={(e) => e.stopPropagation()}
        >
          <div>
            <h3 className="text-center text-black text-xl font-bold">
              Please add the order details!
            </h3>
            <p className="text-center">
              Please add the order address to deliver you AtlasMiner
            </p>
          </div>

          <form
            className="flex flex-col gap-y-3"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="flex gap-5 justify-evenly text-black">
              <div className="w-1/2">
                <label className="label" htmlFor="firstname">
                  First Name
                </label>
                <input
                  id="firstname"
                  name="firstname"
                  type="text"
                  placeholder="Enter your firstname"
                  className={`input input-bordered w-full text-black bg-white placeholder-gray-400`}
                  value={firstname || ''}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="w-1/2">
                <label className="label" htmlFor="lastname">
                  Last Name
                </label>
                <input
                  id="lastname"
                  name="lastname"
                  type="text"
                  placeholder="Enter your lastname"
                  className={`input input-bordered w-full text-black bg-white placeholder-gray-400`}
                  value={lastname || ''}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="text-black">
              <label className="label" htmlFor="lastname">
                Phone Number
              </label>
              <PhoneInput
                country={'ro'}
                value={phoneNumber}
                containerClass="flex flex-col gap-4"
                inputClass={`input input-bordered w-full text-black bg-white placeholder-gray-400`}
                onChange={(e) => setPhoneNumber('+' + e)}
                inputProps={{
                  name: 'phone',
                  required: true,
                }}
              />
            </div>
            <div className="text-black">
              <label className="label" htmlFor="email">
                Enter your email address
              </label>
              <input
                disabled
                id="email"
                name="email"
                type="text"
                placeholder="Enter your email"
                className={`input input-bordered w-full text-black bg-white disabled:border-gray-300 disabled:bg-white disabled:text-black`}
                value={modalPayload.email}
                onChange={handleChange}
              />
            </div>
            <div className="text-black">
              <label className="label" htmlFor="deliveryAddress_address">
                Adress line 1
              </label>
              <input
                id="deliveryAddress_address"
                name="deliveryAddress_address"
                type="text"
                placeholder="Enter your delivery address"
                className={`input input-bordered w-full text-black bg-white placeholder-gray-400`}
                value={deliveryAdress.address || ''}
                onChange={handleChange}
              />
            </div>

            <div className="text-black">
              <label className="label" htmlFor="deliveryAddress_apt">
                Apt, suite
              </label>
              <input
                id="deliveryAddress_apt"
                name="deliveryAddress_apt"
                type="text"
                placeholder="Enter your apt, suite"
                className={`input input-bordered w-full text-black bg-white placeholder-gray-400`}
                value={deliveryAdress.apt || ''}
                onChange={handleChange}
              />
            </div>
            <div className="text-black">
              <label className="label" htmlFor="deliveryAddress_city">
                City
              </label>
              <input
                id="deliveryAddress_city"
                name="deliveryAddress_city"
                type="text"
                placeholder="Enter your city"
                className={`input input-bordered w-full text-black bg-white placeholder-gray-400`}
                value={deliveryAdress.city || ''}
                onChange={handleChange}
              />
            </div>
            <div className="text-black">
              <label className="label" htmlFor="country">
                Country
              </label>
              <input
                id="deliveryAddress_country"
                name="deliveryAddress_country"
                type="text"
                placeholder="Enter your country"
                className={`input input-bordered w-full text-black bg-white placeholder-gray-400`}
                value={deliveryAdress.country || ''}
                onChange={handleChange}
              />
            </div>
            <div className="text-black">
              <label className="label" htmlFor="apt">
                Postcode
              </label>
              <input
                id="deliveryAddress_postcode"
                name="deliveryAddress_postcode"
                type="text"
                placeholder="Postcode"
                className={`input input-bordered w-full text-black bg-white placeholder-gray-400`}
                value={deliveryAdress.postcode || ''}
                onChange={handleChange}
                required
              />
            </div>
            <div className="flex w-full justify-center pt-7">
              <BuyMinerNFT
                deliveryAddress={deliveryAdress}
                email={modalPayload.email}
                firstname={firstname}
                lastname={lastname}
                phone={phoneNumber}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Modal
