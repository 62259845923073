const Decorator = () => {
  return (
    <div className="w-full h-full absolute top-0 left-0 z-2 overflow-hidden">
      <svg
        width="1728"
        height="4639"
        viewBox="0 0 1728 4639"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute left-0 w-full h-full"
      >
        <g opacity="0.1" clipPath="url(#clip0_1_85)">
          <path
            d="M1987.67 509.449L69.5813 808.72L-630.387 2618.83L587.734 4129.66L2505.82 3830.39L3205.79 2020.29L1987.67 509.449Z"
            stroke="white"
            strokeWidth="1.18061"
            strokeMiterlimit="10"
          />
          <path
            d="M212.314 875.227C219.764 865.022 1083.23 724.544 1096.87 722.415C1110.52 720.287 1975.74 591.045 1981.93 599.121C1988.12 607.198 3082.34 1961.44 3084.47 1975.08C3086.6 1988.73 2464.96 3610.68 2457.51 3620.89C2450.06 3631.1 1586.47 3770.77 1576.84 3772.27C1567.21 3773.78 698.104 3904.45 691.911 3896.37C685.718 3888.29 -416.523 2535.31 -418.652 2521.66C-420.78 2508.02 204.863 885.433 212.314 875.227Z"
            stroke="white"
            strokeWidth="1.18061"
            strokeMiterlimit="10"
          />
          <path
            d="M354.372 942.683C370.076 922.147 1137.97 790.827 1165.25 786.57C1192.54 782.312 1964.74 673.338 1985.15 688.24C2005.56 703.141 2967.72 1901.24 2972.48 1931.73C2977.23 1962.23 2425.29 3393.28 2410.89 3416.9C2396.49 3440.52 1627.3 3568.75 1600.01 3573.01C1572.72 3577.27 801.327 3686.12 780.113 3671.34C758.9 3656.56 -202.458 2458.34 -207.216 2427.85C-211.974 2397.35 336.261 963.595 354.372 942.683Z"
            stroke="white"
            strokeWidth="1.18061"
            strokeMiterlimit="10"
          />
          <path
            d="M497.229 1009.99C519.456 978.572 1193.12 854.555 1233.25 848.294C1273.38 842.033 1952.43 752.525 1983.17 775.679C2013.91 798.834 2849.08 1841.64 2855.47 1882.57C2861.85 1923.5 2383.81 3169.56 2361.45 3200.17C2339.1 3230.79 1665.99 3353.08 1625.06 3359.46C1584.13 3365.85 905.754 3454.43 875.138 3432.08C844.521 3409.73 10.2795 2367.6 4.01857 2327.47C-2.24234 2287.34 474.877 1040.61 497.229 1009.99Z"
            stroke="white"
            strokeWidth="1.18061"
            strokeMiterlimit="10"
          />
          <path
            d="M639.962 1076.5C669.765 1035.68 1248.23 923.227 1302 914.838C1355.77 906.448 1941.8 837.208 1982.62 867.011C2023.44 896.813 2729.64 1782.17 2738.16 1836.74C2746.67 1891.31 2343.63 2948.92 2313.82 2989.74C2284.02 3030.57 1705.68 3143.82 1651.11 3152.33C1596.54 3160.85 1011.99 3229.04 971.164 3199.23C930.342 3169.43 223.34 2284.2 214.951 2230.43C206.561 2176.66 606.95 1117.82 639.962 1076.5Z"
            stroke="white"
            strokeWidth="1.18061"
            strokeMiterlimit="10"
          />
          <path
            d="M782.02 1143.96C819.951 1092 1302.84 988.707 1370.25 978.188C1437.67 967.67 1929.87 918.824 1981.03 956.88C2032.18 994.936 2611.01 1722.6 2621.65 1790.81C2632.3 1859.03 2303.45 2728.31 2263.79 2779.71C2224.13 2831.12 1743.77 2934.84 1675.56 2945.48C1607.34 2956.13 1116.74 3004.72 1064.79 2966.79C1012.83 2928.86 435.602 2200.95 425.084 2133.54C414.566 2066.12 744.767 1194.98 782.02 1143.96Z"
            stroke="white"
            strokeWidth="1.18061"
            strokeMiterlimit="10"
          />
          <path
            d="M924.753 1210.46C969.456 1149.23 1357.57 1054.97 1438.63 1042.32C1519.68 1029.68 1918.87 1001.1 1980.1 1045.8C2041.33 1090.51 2492.38 1663 2505.02 1744.06C2517.67 1825.12 2262.47 2507.8 2217.89 2569.84C2173.31 2631.87 1785.07 2725.33 1703.21 2738.1C1621.35 2750.88 1223.77 2779.2 1162.54 2734.5C1101.31 2689.8 650.268 2117.3 637.496 2035.44C624.724 1953.58 877.767 1272.87 924.753 1210.46Z"
            stroke="white"
            strokeWidth="1.18061"
            strokeMiterlimit="10"
          />
          <path
            d="M1066.81 1277.9C1119.76 1206.33 1412.3 1121.23 1507 1106.46C1601.7 1091.68 1907.06 1083.5 1978.5 1135.65C2049.94 1187.8 2372.94 1603.53 2387.71 1698.23C2402.49 1792.93 2222.29 2287.17 2169.46 2359.53C2116.63 2431.9 1824.77 2516.08 1731.67 2530.6C1638.58 2545.13 1332.41 2553.44 1260.17 2501.41C1187.93 2449.38 866.539 2033.4 851.638 1937.9C836.737 1842.4 1014.65 1349.34 1066.81 1277.9Z"
            stroke="white"
            strokeWidth="1.18061"
            strokeMiterlimit="10"
          />
          <path
            d="M1209.67 1345.23C1269.15 1262.79 1466.91 1186.71 1575.26 1169.81C1683.6 1152.91 1895.14 1165.12 1977.71 1225.4C2060.28 1285.68 2254.31 1543.96 2271.21 1652.3C2288.11 1760.65 2181.31 2066.68 2121.83 2149.13C2062.35 2231.57 1861.26 2307.34 1755.32 2323.87C1649.38 2340.4 1435.44 2328.57 1353.8 2268.96C1272.15 2209.36 1077.07 1949.6 1060.17 1841.26C1043.26 1732.91 1148.46 1427.13 1209.67 1345.23Z"
            stroke="white"
            strokeWidth="1.18061"
            strokeMiterlimit="10"
          />
          <path
            d="M2155.08 1608.75C2171.27 1711.03 2151.49 1815.77 2099.11 1905.1C2046.73 1994.43 1965.01 2062.84 1867.84 2098.66C1770.67 2134.48 1664.1 2135.49 1566.27 2101.54C1468.43 2067.58 1385.41 2000.74 1331.34 1912.43C1277.26 1824.11 1255.49 1719.78 1269.73 1617.2C1283.96 1514.63 1333.33 1420.16 1409.41 1349.92C1485.49 1279.66 1583.59 1237.97 1686.97 1231.93C1790.34 1225.91 1892.62 1255.91 1976.35 1316.84C2023.99 1351.23 2064.33 1394.75 2095.01 1444.86C2125.69 1494.97 2146.11 1550.68 2155.08 1608.75Z"
            stroke="white"
            strokeWidth="1.18061"
            strokeMiterlimit="10"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_85">
            <rect
              width="4061.28"
              height="4061.28"
              fill="white"
              transform="translate(-1031.71 626.228) rotate(-8.86813)"
            />
          </clipPath>
        </defs>
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 575 887"
        fill="none"
        className="absolute apply-blur right-0 w-full h-full"
      >
        <g filter="url(#filter0_f_1_167)">
          <path
            d="M198.384 422.786C274.629 342.553 282.344 343.297 276.671 353.698L413.67 160.712C399.909 229.017 373.647 368.813 378.683 381.557C384.979 397.486 375.942 413.73 362.817 424.464C349.692 435.198 325.917 445.79 302.7 474.053C279.483 502.316 363.057 570.97 333.628 680.194C304.199 789.418 120.627 677.347 191.891 608.944C248.902 554.222 233.938 563.876 219.329 575.543C180.579 558.054 122.138 503.019 198.384 422.786Z"
            fill="#8F00FF"
            fillOpacity="0.35"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_1_167"
            x="0.616858"
            y="0.131729"
            width="573.633"
            height="886.349"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="80.2901"
              result="effect1_foregroundBlur_1_167"
            />
          </filter>
        </defs>
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="575"
        height="888"
        viewBox="0 0 575 888"
        fill="none"
        className="absolute apply-blur left-0"
      >
        <g filter="url(#filter0_f_1_166)">
          <path
            d="M198.549 423.456C274.794 343.223 282.51 343.967 276.837 354.368L413.836 161.382C400.075 229.687 373.813 369.483 378.849 382.226C385.144 398.156 376.107 414.399 362.983 425.134C349.858 435.868 326.083 446.46 302.866 474.723C279.649 502.986 363.223 571.64 333.794 680.863C304.365 790.087 120.792 678.017 192.057 609.614C249.068 554.892 234.104 564.545 219.495 576.212C180.744 558.724 122.304 503.688 198.549 423.456Z"
            fill="#AD00FF"
            fillOpacity="0.35"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_1_166"
            x="0.782608"
            y="0.801407"
            width="573.633"
            height="886.349"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="80.2901"
              result="effect1_foregroundBlur_1_166"
            />
          </filter>
        </defs>
      </svg>
    </div>
  )
}

export default Decorator
