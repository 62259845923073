import { lazy, useCallback, useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import PageNotFound from './PageNotFound'
import {
  CHAIN_RPC,
  WEB3MODAL_MAINNET,
  WEB3MODAL_METADATA,
  WEB3MODAL_PROJECT_ID,
} from './config'
import Waitlist from './waitlist/Waitlist'

import { createWeb3Modal, defaultConfig } from '@web3modal/ethers5/react'
import Decorator from './components/Decorators'
import { ModalProvider } from './components/ModalProvider'
import { useWalletState } from './hooks/useWalletState'
import Modal from './waitlist/Modal'
// create config for web3Modal
const ethersConfig = defaultConfig({
  metadata: WEB3MODAL_METADATA,
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: CHAIN_RPC, // used for the Coinbase SDK
  defaultChainId: 1, // used for the Coinbase SDK
})

createWeb3Modal({
  ethersConfig,
  chains: [WEB3MODAL_MAINNET],
  projectId: WEB3MODAL_PROJECT_ID,
})

const ModulePicker = lazy(async () => import('./ModulePicker'))
const NFTMintList = lazy(async () => import('./nft/NFTMintList'))
const KycAuthenticator = lazy(async () => import('./kyc/KycAuthenticator'))
const KycPage = lazy(async () => import('./kyc/KycPage'))
const KycVerifyPage = lazy(async () => import('./kyc/KycVerifyPage'))
const KYCNotFound = lazy(async () => import('./kyc/NotFound'))
const NFTNotFound = lazy(async () => import('./nft/NotFound'))
const StakingCard = lazy(async () => import('./kyc/StakingCard'))
const StakingCardv2 = lazy(async () => import('./kyc/StakingCardv2'))
const Withdrawals = lazy(async () => import('./withdrawals/Withdrawals'))
const Footer = lazy(async () => import('./Footer'))

function App() {
  const navigate = useNavigate()
  const [userWallet, setUserWallet] = useState<string | undefined>('')
  const [availableMintPasses, setAvailableMintPasses] = useState(0)
  const [fetchMintPassLoading, setFetchMintPassLoading] = useState(false)
  const [lastRefresh, setLastRefresh] = useState(Date.now())
  const [jwtToken, setJwtToken] = useState('')
  const location = useLocation() // for now this is used to check if we are on the waitlist page

  const { address, isConnected, open, disconnect } = useWalletState()

  useEffect(() => {
    setUserWallet(address)
  }, [isConnected, address])

  const fetchMintPasses = useCallback(async () => {
    if (!address) {
      return
    }
    setFetchMintPassLoading(true)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/nft/balance/mint-pass?walletAddress=${address}`,
      )
      const data = await response.json()
      setAvailableMintPasses(data.mintPass)
    } catch (e) {
      console.error(e)
    } finally {
      setLastRefresh(Date.now())
      setFetchMintPassLoading(false)
    }
  }, [address])

  useEffect(() => {
    fetchMintPasses()
  }, [fetchMintPasses, address])

  return (
    <ModalProvider>
      <div className="min-h-screen min-w-full flex flex-col items-center">
        <div className="navbar bg-neutral flex flex-row justify-center relative z-2">
          <div className="max-w-7xl px-2 flex-1 flex flex-row justify-between items-center">
            <a
              href="/"
              className="cursor-pointer hover:bg-base-100 rounded-xl"
              onClick={() => navigate('/')}
            >
              <img
                src="https://static.tildacdn.com/tild6536-3438-4434-b634-656130653330/ATLAS_NAVI_LOGO.svg"
                width="100"
                className="p-2"
                alt="logo"
              />
            </a>
            <div className="flex flex-row items-center justify-end">
              {location.pathname !== '/miner' && address && (
                <button
                  type="button"
                  onClick={async () => {
                    if (lastRefresh + 60000 > Date.now()) {
                      toast.info('Please wait a bit before refreshing again')
                      return
                    }

                    await fetchMintPasses()
                  }}
                  disabled={fetchMintPassLoading}
                  className="inline-flex w-auto mr-5 cursor-pointer select-none appearance-none items-center justify-center space-x-2 rounded border border-blue-700 bg-blue-700 px-3 py-2 text-sm font-medium text-white transition hover:border-blue-800 hover:bg-blue-800 focus:border-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-300 disabled:pointer-events-none disabled:opacity-75"
                >
                  {fetchMintPassLoading ? (
                    <svg className="h-4 w-4 animate-spin" viewBox="3 3 18 18">
                      <path
                        className="fill-blue-800"
                        d="M12 5C8.13401 5 5 8.13401 5 12c0 3.866 3.13401 7 7 7 3.866.0 7-3.134 7-7 0-3.86599-3.134-7-7-7zM3 12c0-4.97056 4.02944-9 9-9 4.9706.0 9 4.02944 9 9 0 4.9706-4.0294 9-9 9-4.97056.0-9-4.0294-9-9z"
                      ></path>
                      <path
                        className="fill-blue-100"
                        d="M16.9497 7.05015c-2.7336-2.73367-7.16578-2.73367-9.89945.0-.39052.39052-1.02369.39052-1.41421.0-.39053-.39053-.39053-1.02369.0-1.41422 3.51472-3.51472 9.21316-3.51472 12.72796.0C18.7545 6.02646 18.7545 6.65962 18.364 7.05015c-.390599999999999.39052-1.0237.39052-1.4143.0z"
                      ></path>
                    </svg>
                  ) : null}
                  {fetchMintPassLoading ? (
                    <span>Loading...</span>
                  ) : (
                    <span>{availableMintPasses} Mint Passes</span>
                  )}
                </button>
              )}
              {location.pathname !== '/miner' &&
                (isConnected ? (
                  <button
                    className="inline-flex w-auto cursor-pointer select-none appearance-none items-center justify-center space-x-2 rounded border border-blue-700 bg-blue-700 px-3 py-2 text-sm font-medium text-white transition hover:border-blue-800 hover:bg-blue-800 focus:border-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-300 disabled:pointer-events-none disabled:opacity-75"
                    type="button"
                    onClick={() =>
                      disconnect()
                        .then(() => {
                          setJwtToken('')
                          setUserWallet('')
                        })
                        .catch((err) => console.log(err))
                    }
                  >
                    Disconnect wallet
                  </button>
                ) : (
                  <button
                    onClick={() => open()}
                    className="inline-flex w-auto cursor-pointer select-none appearance-none items-center justify-center space-x-2 rounded border border-blue-700 bg-blue-700 px-3 py-2 text-sm font-medium text-white transition hover:border-blue-800 hover:bg-blue-800 focus:border-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-300 disabled:pointer-events-none disabled:opacity-75"
                  >
                    Connect Wallet
                  </button>
                ))}
            </div>
          </div>
        </div>
        <main
          className={`w-full flex flex-row flex-grow justify-center items-start ${
            location.pathname === '/miner' && 'main-waitlist relative z-1'
          }`}
        >
          {location.pathname === '/miner' && <Decorator />}
          <div className="max-w-7xl py-6 z-2 relative flex-1 flex-grow flex flex-col">
            <Routes>
              <Route path="*" element={<PageNotFound />} />

              <Route path="/" element={<ModulePicker />} />
              <Route
                path="/nft"
                element={<NFTMintList fetchMintPasses={fetchMintPasses} />}
              />
              <Route
                path="/kyc"
                element={<KycAuthenticator setUserWallet={setUserWallet} />}
              />

              <Route path="/kyc/:wallet" element={<KycPage />} />
              <Route
                path="/kyc/wallet/:wallet"
                element={<KycVerifyPage userWallet={userWallet} />}
              />

              <Route
                path="/kyc/notfound"
                element={<KYCNotFound userWallet={userWallet} />}
              />
              <Route
                path="/nft/notfound"
                element={<NFTNotFound userWallet={userWallet} />}
              />

              <Route
                path="/kyc/staking"
                element={<StakingCard userWallet={userWallet} />}
              />

              <Route
                path="/stakingv2"
                element={<StakingCardv2 userWallet={userWallet} />}
              />

              <Route path="/withdraw" element={<Withdrawals />} />

              <Route path="/miner" element={<Waitlist />} />
            </Routes>
          </div>
        </main>
        <div className="footer">
          <Footer />
        </div>
        <ToastContainer />
      </div>

      <Modal />
    </ModalProvider>
  )
}

export default App
