const Logos = () => {
  return (
    <div className="w-full flex gap-10 flex-wrap justify-center flex-1 my-10 px-20">
      <img src="/assets/metamask.svg" alt="" />
      <img src="/assets/trust-wallet.svg" alt="" />
      <img src="/assets/wallet-connect.svg" alt="" />
      <img src="/assets/safepal.svg" alt="" />
      <img src="/assets/exodus.svg" alt="" />
    </div>
  )
}

export default Logos
