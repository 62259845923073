import { useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { API_URL, RECAPTCHA_SITE_KEY } from '../config'
import WaitlistFormCode from './WaitlistFormCode'

const WaitlistFormMail = (props: any) => {
  const { jwtToken, setWaitlistEntry } = props
  const [mail, setMail] = useState('')
  const [error, setError] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [captchaToken, setCaptchaToken] = useState('')

  const validateValues = (mail: string) => {
    let error = ''

    if (captchaToken === '') {
      error = 'Please complete captcha'
    }

    if (mail.length < 4) {
      error = 'Email is required'
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

      if (!emailRegex.test(mail)) {
        error = 'Email is invalid'
      }
    }

    setError(error)
  }

  const submitForm = async () => {
    const sendCodeRes = await fetch(API_URL + '/miner/email/send-code', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwtToken}`,
      },
      body: JSON.stringify({
        email: mail,
        token: captchaToken,
      }),
    })
    if (!sendCodeRes.ok) {
      if (sendCodeRes.status === 401) {
        setError('Access denied for the requested resource. Please sign again')
      } else {
        const parseSendCodeRes = await sendCodeRes.json()
        if (parseSendCodeRes.error) {
          setError(parseSendCodeRes.message)
        }
      }
      setFormSubmitted(false)
      return
    }

    // submit form
    setFormSubmitted(true)
  }

  useEffect(() => {
    // submit if there are no errors
    if (error === '' && submitting && captchaToken !== '') {
      submitForm()
    }
  }, [captchaToken, error, submitting])

  const handleChange = (e: any) => {
    setMail(e.target.value)
  }

  const onChangeRecaptcha = (value: any) => {
    setCaptchaToken(value)
  }

  return (
    <>
      {formSubmitted ? (
        <WaitlistFormCode
          jwtToken={jwtToken}
          setWaitlistEntry={setWaitlistEntry}
        />
      ) : (
        <>
          <p className="justify-center items-center text-white">
            Please enter you mail address to get on the waitlist
          </p>
          <form
            className="flex flex-col gap-3"
            onSubmit={(e) => {
              e.preventDefault()
              validateValues(mail)
              setSubmitting(true)
            }}
          >
            <label className="form-control w-full">
              <div className="label justify-start">
                {error && (
                  <span className="label-text-alt text-error">{error}</span>
                )}
              </div>
              <div className="relative w-full max-w-[600px] flex mx-auto">
                <div className="absolute top-0 flex w-full justify-center">
                  <div className="h-[1px] animate-border-width rounded-full bg-gradient-to-r from-[rgba(17,17,17,0)] via-black to-[rgba(17,17,17,0)] transition-all duration-1000"></div>
                </div>
                <input
                  id="email"
                  name="email"
                  placeholder="Enter your email address"
                  className={`input input-bordered w-full text-black bg-white placeholder-black ${
                    error ? 'input-error' : 'border-black/40'
                  }`}
                  value={mail || ''}
                  onChange={handleChange}
                />
              </div>
            </label>

            <div className="form-control w-full mt-4 loading loading-ring loading-lg flex align-middle">
              <div className="w-[240px] xs:w-full xs:max-w-[304px] recaptcha overflow-hidden max-md:mx-auto">
                <ReCAPTCHA
                  sitekey={RECAPTCHA_SITE_KEY!}
                  onChange={onChangeRecaptcha}
                />
              </div>
              <button
                className="inline-flex w-auto cursor-pointer mb-5 select-none appearance-none items-center justify-center space-x-2 rounded border border-blue-700 bg-blue-700 px-3 py-2 text-sm font-medium text-white transition hover:border-blue-800 hover:bg-blue-800 focus:border-blue-300 focus:outline-none focus:ring-2 focus:ring-blue-300 disabled:pointer-events-none disabled:opacity-75 max-w-[260px] max-md:mx-auto"
                type="submit"
              >
                Join the waitlist
              </button>
            </div>
          </form>
        </>
      )}
    </>
  )
}

export default WaitlistFormMail
