const WaitingListConfirmation = () => {
  return (
    <div className="block">
      <h1 className="text-2xl font-bold text-white">
        Thank you for joining the waitlist!
      </h1>
      <p className="text-lg text-center mt-2 text-black">
        We will send you an email once you're off the waitlist.
      </p>
    </div>
  )
}

export default WaitingListConfirmation
